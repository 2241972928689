.header-wrapper {
    width: 100%;
    height: 70vh;
    background: #f3f3f3;
    margin-top: 70px;
}

@media only screen and (max-width: 600px) {
    .header-wrapper {
        height: 100vh;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .header-content {
        margin-top: -120px;
    }
}

.title-holder {
    margin: 0 auto;
    height: 70vh;
    width: 50%;
    color: var(--white);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.title-bold {
    font-family: RobotoBold;
    line-height: 1.3em;
}

.header-content {
    height: 70vh;
}

.waves {
    width: 100%;
    height: 50px;
    position: absolute;
    top: 50vh;
}

.most-top {
    position: relative;
    z-index: 999!important;
}

.header-image {
    max-height: 60vh;
    width: 100%;
}