.btn-primary {
    background: var(--green);
    border: none;
    border-radius: 0;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.btn-primary:hover {
    background: var(--dark-green);
}

.btn-secondary {
    border: none;
    border-radius: 0;
    min-height: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.sidebar {
    top: 70px
}

.category {
    color: var(--green);
}

.category-li {
    margin-bottom: 9px;
}

.category-link {
    color: inherit;
    text-decoration: none;
    transition: 0.2s all ease-in-out;
}

.category-link:hover {
    color: var(--green);
    transition: 0.2s all ease-in-out;
}

.header-socials {
    font-size: 35px;
    color: var(--green);
    padding-right: 10px;
    transition: 0.3s ease-in-out all;
}

.header-socials:hover {
    color: var(--dark-green);
    transition: 0.3s ease-in-out all;
}

.desktop-hidden {
    display: none;
}

@media screen and (max-width:500px) {
    .mobile-hidden {
        display: none;
    }

    .desktop-hidden {
        display: block;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 1.3em;
        margin-bottom: 25px;
    }

    .margin-bottom-0-mobile {
        margin-bottom: -150px!important;
    }

    .mobile-top-lift {
        margin-top: -150px;
    }

    .category-list-off-mobile {
        display: none;
    }

    .remove-bottom-margin-mobile {
        margin-bottom: -30px!important;
    }

    .mobile-padding-bottom {
        padding-bottom: 40px;
    }

    .show-category-button {
        font-size: 1em;
    }

    .hide-header {
        display: none;
    }
}

@media screen and (min-width:501px) and (max-width:999px) {
    .mobile-hidden {
        display: none;
    }

    .tablet-hide {
        display: none!important;
    }

    .tablet-show {
        display: block;
    }

    .desktop-hidden {
        display: block;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 1.3em;
        margin-bottom: 25px;
    }

    .margin-bottom-0-mobile {
        margin-bottom: -150px!important;
    }

    .mobile-top-lift {
        margin-top: -150px;
    }

    .category-list-off-mobile {
        display: none;
    }

    .remove-bottom-margin-mobile {
        margin-bottom: -30px!important;
    }

    .mobile-padding-bottom {
        padding-bottom: 40px;
    }

    .show-category-button {
        font-size: 1em;
    }
}
