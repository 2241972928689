.recommended-link {
    color: inherit;
    text-decoration: none;
}

.recommended-link:hover {
    color: inherit;
}

.recommended-product-image {
    height: 200px;
    width: 200px;
    object-fit: contain;
    padding: 10px;
}

.recommended-column {
    min-height: 300px;
    text-align: center;
}

