.product-image {
    width: 100%;
    height: 160px;
    object-fit: contain;
}

.separator {
    border-bottom: 1px solid var(--light-gray);
}

.product-card-link {
    color: inherit;
    text-decoration: none;
}

.product-card-link:hover {
    color: inherit;
}