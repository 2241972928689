:root {
  --black: #000000;
  --green: #1ea951;
  --dark-green: #17803d;
  --gray: #525960;
  --light-gray: #e5e5e5;
  --dark-gray: #343a40;
  --white: #ffffff;
}

@font-face {
  font-family: "RobotoRegular";
  src: local("RobotoRegular"),
  url("./../src/Config/Fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoThin";
  src: local("RobotoThin"),
  url("./../src/Config/Fonts/Roboto/Roboto-Thin.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoLight";
  src: local("RobotoLight"),
  url("./../src/Config/Fonts/Roboto/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoBold";
  src: local("RobotoBold"),
  url("./../src/Config/Fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "RobotoMedium";
  src: local("RobotoMedium"),
  url("./../src/Config/Fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: normal;
}

* {
  font-family: RobotoLight;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
  outline:0 !important;
  -webkit-appearance:none;
  box-shadow: none !important;
  border-color: var(--green)!important;
}

.btn-primary:active {
  background: var(--green)!important;
}

.card {
  border: none!important;
  border-radius: 0!important;
}

ul {
  list-style: none;
  margin-left: -15px;
  margin-right: 10px;
}

.text-green {
  color: var(--green)!important;
}

.text-bold {
  font-family: RobotoBold;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg:rgb(55 227 39 / 12%)!important;
}

tr {
  border: transparent!important;
}