.badge-available {
    background: var(--green);
    padding: 5px;
    color: var(--white);
}

.badge-unavailable {
    background: #ff4747;
    padding: 5px;
    color: var(--white);
}

.product-image-detail {
    width: 100%;
    height: 300px;
    object-fit: contain;
}