.footer-wrapper {
    width: 100%;
    min-height: 400px;
    background: var(--black);
    bottom: 0;
}

.footer-link {
    text-decoration: none;
    color: gray;
    transition: 0.3s all ease-in-out;
}

.footer-link:hover {
    color: var(--green);
    transition: 0.3s all ease-in-out;
}

.social {
    font-size: 1.5em;
}

.snap-link {
    color: var(--green)!important;
    text-decoration: none!important;
}
