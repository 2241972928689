.call-button {
    background: var(--green);
    height: 50px;
    color: white;
    line-height: 50px;
    text-align: center;
    display: none;
}

@media only screen and (max-width: 600px) {
    .call-button {
        display: block;
    }
}