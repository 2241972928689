.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    font-size: 29px;
    border: none;
    outline: none;
    background-color: var(--green);
    color: white;
    cursor: pointer;
    padding: 3px;
    width: 50px;
    border-radius: 50px;
}

@media only screen and (max-width: 600px) {
    .back-to-top {
        bottom: 70px;
    }
}